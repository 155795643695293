import request from "../utils/request";
const baseURL = "/opt/";

// 到款录入（内容识别）
export function identifyContent(params) {
  return request({
    url: `${baseURL}accounts/identifyContent`,
    method: "post",
    data: params,
  });
}
// 确认到款信息
export function confirmCash(params) {
  return request({
    url: `${baseURL}accounts/confirmCash`,
    method: "post",
    data: params,
  });
}
// 验证到款信息
// opt/accounts/verificationCash
export function verificationCash(params) {
  return request({
    url: `${baseURL}accounts/verificationCash`,
    method: "post",
    data: params,
  });
}
