import Vue from "vue";
import { mapState } from "vuex";
import "../../styles/reimburse.css";
import { identifyContent, getAccounts, confirmCash } from "../../api/reimburse";
import {
  NavBar,
  Dialog,
  DatetimePicker,
  Calendar,
  Popup,
  Form,
  Field,
  Toast,
  Uploader,
  Overlay,
  Button,
  Radio,
  Picker,
  Grid,
  GridItem,
  RadioGroup,
  Collapse,
  CollapseItem,
  List,
  Cell,
} from "vant";
import { verificationCash } from "@/api/financialManagement";
Vue.use(NavBar)
  .use(DatetimePicker)
  .use(Dialog)
  .use(Calendar)
  .use(Popup)
  .use(RadioGroup)
  .use(Toast)
  .use(Form)
  .use(Overlay)
  .use(Uploader)
  .use(Field)
  .use(Button)
  .use(Radio)
  .use(Picker)
  .use(Grid)
  .use(GridItem)
  .use(Collapse)
  .use(CollapseItem)
  .use(List)
  .use(Cell);

export default {
  name: "received",
  data() {
    return {
      content: "",
      value: "",

      msgs: {
        customerName: "",
        customerBankNo: "",
        companyName: "",
        companyBankNo: "",
        paymentDate: "",
        paymentAmount: "",
        companyId: "",
        customerId: "",
        remark: "",
      },
      //   inStatus: 1,
      list: [],
    };
  },
  created() {},
  computed: {
    ...mapState({
      apiError: (state) => state.apiError,
    }),
  },
  // 报销明细总金额
  methods: {
    distinguish() {
      let obj = {};
      // obj.accountsId = item.accountsId;
      obj.customerId = "";
      obj.customerName = "";
      obj.customerBankNo = "";
      obj.companyId = "";
      obj.companyBankNo = "";
      obj.paymentDate = "";
      obj.paymentAmount = "";
      obj.remark = "";

      identifyContent({ content: this.content }).then((res) => {
        if (res.success == true) {
          this.msgs = res.resultData;
        } else {
          confirm(res.resultMsg);
        }
      });
    },
    toUp() {
      this.inStatus = 1;
    },
    toNext() {
      getAccounts({
        companyId: this.msgs.companyId,
        customerId: this.msgs.customerId,
      }).then((r) => {
        if (r.success) {
          this.list = [...r.resultData];
          //   this.inStatus = 3;
        }
      });
    },
    send() {
      let obj = {};
      // obj.accountsId = item.accountsId;
      obj.customerName = this.msgs.customerName;
      obj.customerBankNo = this.msgs.customerBankNo;
      obj.companyName = this.msgs.companyName;
      obj.companyBankNo = this.msgs.companyBankNo;
      obj.paymentDate = this.msgs.paymentDate;
      obj.paymentAmount = this.msgs.paymentAmount;
      obj.remark = this.msgs.remark;
      verificationCash(obj).then((res) => {
        // res为undefined的情况，相同内容也要提交
        if (res.success == false) {
          const revalOne = confirm(`${res.resultMsg}?`);
        } else {
          const reval = confirm(`${res.resultData}?`);
          if (reval == true) {
            confirmCash(obj).then((res) => {
              if (res.success) {
                Toast("录入成功！");
                this.content = "";
                this.msgs.customerName = "";
                this.msgs.customerBankNo = "";
                this.msgs.companyName = "";
                this.msgs.companyBankNo = "";
                this.msgs.paymentDate = "";
                this.msgs.paymentAmount = "";
              }
            });
          }
        }
      });
    },

    onClickLeft() {
      this.$router.back();
    },
  },
};
